import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';

declare var google: any;

@Component({
  selector: 'div.googleMapPolygons',
  templateUrl: './google-map-polygons.component.html',
  styleUrls: ['./google-map-polygons.component.css']
})
export class GoogleMapPolygonsComponent implements OnInit, OnDestroy {

  // usage in components - ta4-delivery-lines, ta1-warehouse-plan, ta1-company-obligation

  private _map: any;
  private _initMarker: GoogleMapMarker = null;
  private _initMarkers: Array<GoogleMapMarker> = [];
  private _initialized: boolean = false;
  private _centeredSwitch: boolean = false;
  private _bounds: any;
  private _polyLines: Array<any> = [];
  private _infoWindows: Array<any> = [];

  private _DEFAULT_ZOOM: number = 7;
  private _FOCUS_ZOOM: number = 18;

  private _key: number;
  get key(): number {
    return this._key;
  }
  
  private _properties = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
    if (this._properties && this._properties['zoom']) {
      this._DEFAULT_ZOOM = this._properties['zoom'];
    }
  }

  // multiple markers
  private _markers: Array<GoogleMapMarker> = [];
  @Input()
  set markers(markers: Array<GoogleMapMarker>) {
    let same: boolean = markers === this._initMarkers;
    // removing not null
    this._initMarkers = markers.filter(m => m);

    if (!same && this._initialized) {
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this.rebuildData(false);
    }
  }
  
  // only one marker (ta1-company-obligation confirmation modal)
  private _marker: GoogleMapMarker = null;
  @Input()
  set marker(marker: GoogleMapMarker) {
    let same: boolean = marker === this._initMarker;
    this._initMarker = marker;

    if (!same && this._initialized) {
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this.rebuildData(false);
    }
  }

  private _polygon: any = false;
  @Input()
  public set polygon(value: any) {
    // remove map from previous version of polygon
    if (this._polygon) {
      this._polygon.setMap(null);
    }
    // new area of polygon
    this._polygon = value;
    if (this._polygon) {
      this._polygon.setMap(this._map);
    }
  }

  private _polygons: any = false;
  @Input()
  public set polygons(value: Array<any>) {
    // remove map from previous version of polygons
    if (this._polygons && this._polygons.length) {
      this._polygons.forEach(
        p => {
          p.setMap(null);
        }
      );
    }
    // new area of polygon
    this._polygons = value;
    if (this._polygons && this._polygons.length) {
      this._polygons.forEach(
        p => {
          p.setMap(this._map);
        }
      );
    }
  }

  private _fitPolygon: any = false;
  @Input()
  public set fitPolygon(value: any) {
    this._fitPolygon = value;
  }

  private _allowMultipleInfoWindow: boolean = false;
  @Input()
  set multiInfoWindow(value: boolean) {
    this._allowMultipleInfoWindow = value;
  }
  
  private _drawOnlyLines: boolean = false;
  @Input()
  set drawOnlyLines(value: boolean) {
    this._drawOnlyLines = value;
  }

  private _nocenterWarehouse: any = false;
  @Input()
  public set nocenterWarehouse(value: any) {
    this._nocenterWarehouse = value;
  }

  private _centerOnMarkers: boolean = false;
  @Input()
  set centerOnMarkers(value: boolean) {
    this._centerOnMarkers = value;
  }

  private _displayFocusOnMarker: boolean = false;
  @Input()
  set displayFocusOnMarker(value: boolean) {
    this._displayFocusOnMarker = value;
  }
  get displayFocusOnMarker(): boolean {
    return this._displayFocusOnMarker;
  }

  private _mapClickCallback: Function = null;
  private _clickEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  get clicked(): Observable<any> {
    return this._clickEmitter.asObservable();
  }

  constructor(
    private _elRef: ElementRef,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
  }

  ngOnInit(): void {
    this.buildData();
    this._initialized = true;

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-favourites';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearData(true);
  }
  
  private clearData(dropMap: boolean) {
    this._infoWindows.forEach(
      infoWindow => {
        infoWindow.close();
        infoWindow.setMap(null);
      }
    );
    this._infoWindows = [];
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];
    this._markers.forEach(
      marker => {
        marker.clearEvents();
        marker.map = null;
      }
    );
    
    if (this._marker) {
      this._marker.clearEvents();
      this._marker.map = null;
    }
    if (this._initMarker) {
      this._initMarker.clearEvents();
      this._initMarker.map = null;
    }
    this._marker = this._initMarker;

    this._initMarkers.forEach(
      marker => {
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._markers = this._initMarkers.slice();

    this._bounds = null;
    if (dropMap) {
      this._map = null;
    }
  }

  
  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this._rebuildTimeout = window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }

  private buildData() {
    this._building = true;
    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
      if (this._mapClickCallback) {
        this._map.addListener('click', (data) => {
          this._mapClickCallback.apply(this, [data]);
          this._clickEmitter.emit(data);
        });
      } 
      else {
        this._map.addListener('click', (data) => {
          this._clickEmitter.emit(data);
        });
      }
    }

    // info windows
    if (!this._allowMultipleInfoWindow) {
      this._infoWindows = [
        new google.maps.InfoWindow({})
      ]
    }

    // handle multiple markers
    this._markers = this._initMarkers.slice();

    if (this._drawOnlyLines && this._markers.length > 1) {
      // creating polylines to our map
      this._markers.forEach(
        (marker, index) => {
          if (this._markers[index + 1]) {
            this._polyLines.push(
              new google.maps.Polyline({
                path: [
                  marker.position,
                  this._markers[index + 1].position
                ],
                geodesic: true,
                strokeColor: '#0000ff',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                zIndex: 1000,
                map: this._map
              })
            );
          }
        }
      );
    }

    // handle multiple markers
    if (this._markers.length) {
      this._markers.forEach(
        (marker: GoogleMapMarker) => {
          this.setMarker(marker);
        }
      );
      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        marker => {
          if (this._nocenterWarehouse && marker.icon && marker.icon.includes('warehouse')) {
            // continue foreach
            return;
          }
          this._bounds.extend(marker.position);
        }
      );
    }

    if (this._map) {
      // handle only one marker
      this._marker = this._initMarker;
      if (this._marker) {
        this.setMarker(this._marker);
        this._map.setCenter(this._marker.position);
        this._map.setZoom(this._DEFAULT_ZOOM);
        this._centeredSwitch = false;
      }

      // disable zooming when creating polygons
      if (this._markers.length && (!this._polygon || this._fitPolygon)) {
        if (this._bounds && this._markers.length > 1) {
          this._map.fitBounds(this._bounds);
        } 
        else {
          if (this._markers.length) {
            this._map.setCenter(this._markers[0].position);
          }
          this._map.setZoom(this._DEFAULT_ZOOM);
        }
      }

      // fix for showing polygons - invoke setter
      if (this._polygons && this._polygons.length) {
        this.polygons = this._polygons;
      }
    }

    this._building = false;
  }

  
  /*************************************************************/
  // Markers managing
  /*************************************************************/
  private setMarker(marker: GoogleMapMarker): GoogleMapMarker {
    marker.map = this._map;
    // info window of marker
    if (marker.infoWindowContent && !marker.infoWindow) {
      let infowindow: any;
      if (this._allowMultipleInfoWindow) {
        infowindow = new google.maps.InfoWindow({});
        this._infoWindows.push(infowindow);
      } 
      else {
        infowindow = this._infoWindows[0];
      }
      marker.infoWindow = infowindow;
      if (marker.autoInfoWindowOpen) {
        infowindow.setContent(marker.infoWindowContent);
        infowindow.open(this._map, marker.getGoogleMarker());
      }
    }
    else if (!marker.infoWindowContent) {
      marker.addListener('click', () => {
        if (this._centerOnMarkers) {
          this.centerToMarker(marker);
        }
      });
    }
    return marker;
  }
  
  
  /*************************************************************/
  // Centering stuff
  /*************************************************************/
  centerToMarker(marker: GoogleMapMarker) {
    this.centerToPosition(marker.position);
  }

  centerToFirstMarker() {
    if (this._initMarker) {
      this.centerToPosition(this._initMarker.position);
    }
  }

  private centerToPosition(position: any) {
    if (!this._centeredSwitch) {
      this._map.setZoom(this._FOCUS_ZOOM);
      this._map.setCenter(position);
      this._map.setMapTypeId(google.maps.MapTypeId.HYBRID);

      this._centeredSwitch = true;
    } 
    else {
      // this.rebuildData(false);
      this._map.setZoom(this._DEFAULT_ZOOM);
      this._map.setCenter(position);
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);

      this._centeredSwitch = false;
    }
  }
}
