import { Component, Input, Output, OnDestroy, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';

import { CompanyService } from 'src/app/service/company.service';
import { ServiceTaskService } from 'src/app/service/service-task.service';
import { ServiceEventService } from 'src/app/service/service-event.service';
import { TrackingEventService } from 'src/app/service/tracking-event.service';
import { ServiceEventObject } from 'src/app/model/service-event.object';
import { TrackingEvent } from 'src/app/model/tracking-event.object';
import { ServiceTask } from 'src/app/model/service-task.object';
import { Trailer } from 'src/app/model/trailer.object';
import { Vehicle } from 'src/app/model/vehicle.object';
import { Company } from 'src/app/model/company.object';
import { DateTools } from 'src/app/tools/DateTools';

declare var $: any;

@Component({
  selector: 'tm-services-modals',
  templateUrl: './tm-services-modals.component.html',
  styleUrls: ['./tm-services-modals.component.css']
})
export class TmServicesModalsComponent implements OnInit, OnDestroy {
  
  private _subscribed: Array<Subscription> = [];
  
  private _company: Company;

  private _vehicle: Vehicle = null;
  @Input()
  public set vehicle(value: Vehicle) {
    this._vehicle = value;
  }
  public get vehicle(): Vehicle {
    return this._vehicle;
  }
  
  private _trailer: Trailer = null;
  @Input()
  public set trailer(value: Trailer) {
    this._trailer = value;
  }
  public get trailer(): Trailer {
    return this._trailer;
  }

  private _vehicles: Array<Vehicle> = [];
  @Input()
  public set vehicles(value: Array<Vehicle>) {
    this._vehicles = value;
  }
  public get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }

  private _trailers: Array<Trailer> = [];
  @Input()
  public set trailers(value: Array<Trailer>) {
    this._trailers = value;
  }
  public get trailers(): Array<Trailer> {
    return this._trailers;
  }

  private _newTask: ServiceTask = null;
  @Input()
  public set newTask(value: ServiceTask) {
    // null possibly previous edit
    this._editTask = null;
    this._newTask = value;
    this._fuelEvents = [];
    this._trackingEvents = [];
    // form init
    this.initNewTaskForm();
    if (this._newTask) { 
      this.newTaskForm.controls.car_key.setValue(this._newTask.car_key);
      this.newTaskForm.controls.trailer_key.setValue(this._newTask.trailer_key);
      this.newTaskForm.controls.currency.setValue(this.our_currency);
      this.newTaskForm.controls.task.setValue(this._newTask.task);
      this.newTaskChange();
    }
  }
  public get newTask(): ServiceTask {
    return this._newTask;
  }

  private _editTask: ServiceTask = null;
  @Input()
  public set editTask(value: ServiceTask) {
    // null possibly previous new
    this._newTask = null;
    this._editTask = value;
    this._fuelEvents = [];
    this._trackingEvents = [];

    // form init
    this.initEditTaskForm();
    if (this._editTask) {
      this.editTaskForm.controls.car_key.setValue(this.editTask.car_key);
      this.editTaskForm.controls.trailer_key.setValue(this.editTask.trailer_key);
      this.editTaskForm.controls.amortization_interval.setValue(this.editTask.amortization_interval);
      this.editTaskForm.controls.amortization_unit.setValue(this.editTask.amortization_unit);
      this.editTaskForm.controls.code.setValue(this.editTask.code);
      this.editTaskForm.controls.currency.setValue(this.editTask.currency);
      this.editTaskForm.controls.completed_date.setValue(this.editTask.completed_date);
      this.editTaskForm.controls.completed_km.setValue(this.editTask.completed_km);
      this.editTaskForm.controls.due_date.setValue(this.editTask.due_date);
      this.editTaskForm.controls.due_km.setValue(this.editTask.due_km);
      this.editTaskForm.controls.insurance_benefits.setValue(this.editTask.insurance_benefits);
      this.editTaskForm.controls.price.setValue(this.editTask.price);
      this.editTaskForm.controls.price_depriciated.setValue(this.editTask.price_depriciated);
      this.editTaskForm.controls.note.setValue(this.editTask.note);
      this.editTaskForm.controls.reminder1.setValue(this.editTask.reminder1);
      this.editTaskForm.controls.reminder2.setValue(this.editTask.reminder2);
      this.editTaskForm.controls.task.setValue(this.editTask.task);
      this.editTaskForm.controls.type.setValue(this.editTask.type);
    }
  }
  public get editTask(): ServiceTask {
    return this._editTask;
  }
  
  // output for creating task
  @Output() updateTask: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Output() updatedTaskObject: EventEmitter<ServiceTask> = new EventEmitter<ServiceTask>();
  private _updatedTaskObject: EventEmitter<ServiceTask> = new EventEmitter();
  @Output()
  get updatedTaskObject(): Observable<ServiceTask> {
    return this._updatedTaskObject.asObservable();
  }

  constructor(
    private _serviceEventServ: ServiceEventService,
    private _trackingEventServ: TrackingEventService,
    private _serviceTaskServ: ServiceTaskService,
    private _companyServ: CompanyService,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe
  ) { 
  }

  ngOnInit(): void {
    this.initNewTaskForm();
    this.initEditTaskForm();
    this.initPlanTaskForm();

    this._subscribed.push(
      this._companyServ.getCompanyFullObservable().subscribe(
        company => {
          if (company) {
            this._company = company;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
  }

  // get default currency of our company
  get our_currency(): string {
    return this._companyServ.country == 'CZ' ? 'CZK' : 'EUR';
  }

  
  /***************************************************/
  /* Creating new service tasks */
  /***************************************************/
  public newTaskForm: FormGroup;
  public newTaskFormSubmitted = false;
  // convenience getter for easy access to form fields
  get f_new() { 
    return this.newTaskForm.controls; 
  }

  initNewTaskForm(): void {
    this.newTaskForm = this._formBuilder.group({
      car_key: new FormControl({value: null, disabled: this._vehicle ? true : false}),
      trailer_key: new FormControl({value: null, disabled: this._trailer ? true : false}),
      amortization_interval: [null],
      amortization_unit: [null],
      code: [null, [Validators.required, Validators.maxLength(40)]],
      currency: [null],
      completed_date: [null],
      completed_km: [null],
      due_date: [null],
      due_km: [null],
      insurance_benefits: [null],
      price: [null],
      price_depriciated: [null],
      note: ['', Validators.maxLength(250)],
      reminder1: [null],
      reminder2: [null],
      task: [null, Validators.required],
      type: ['A']
    });
  }

  get isCarNewTask(): boolean {
    return this.newTask.car_key != null && this.newTask.car_key != undefined;
  }

  get isTrailerNewTask(): boolean {
    return this.newTask.trailer_key != null && this.newTask.trailer_key != undefined;
  }

  newTaskChange(): void {
    let value = this.f_new.task.value;
    switch (value) {
      case 'A':
        this.newTaskForm.controls.code.setValue(ServiceTask.MYTO_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'B':
        this.newTaskForm.controls.code.setValue(ServiceTask.BATTERY_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'C':
        this.newTaskForm.controls.code.setValue(ServiceTask.PARKING_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'D':
        this.newTaskForm.controls.code.setValue(ServiceTask.DILY_NAKUP_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'G':
        this.newTaskForm.controls.code.setValue(ServiceTask.GARANCNI_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'H':
        this.newTaskForm.controls.code.setValue(ServiceTask.HAVARIE_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'I':
        this.newTaskForm.controls.code.setValue(ServiceTask.POJISTENI_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'L':
        this.newTaskForm.controls.code.setValue(ServiceTask.L_CERTIFIKAT_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'M':
        this.newTaskForm.controls.code.setValue(ServiceTask.MYTI_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'N':
        this.newTaskForm.controls.code.setValue(ServiceTask.NAKUP_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'O':
        this.newTaskForm.controls.code.setValue(ServiceTask.OIL_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'P':
        this.newTaskForm.controls.code.setValue(ServiceTask.PNEU1_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'Q':
        this.newTaskForm.controls.code.setValue(ServiceTask.PNEU2_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'R':
        this.newTaskForm.controls.code.setValue(ServiceTask.PNEU3_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'V':
        this.newTaskForm.controls.code.setValue(ServiceTask.TACHO_KALIBRACE_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'W':
        this.newTaskForm.controls.code.setValue(ServiceTask.TACHO_ZALOHA_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'T':
        this.newTaskForm.controls.code.setValue(ServiceTask.TECHNICKA_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'Y':
        this.newTaskForm.controls.code.setValue(ServiceTask.NADSTANDARD_NAME);
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_NADSTANDARD); 
        break;
      case 'Z':
        this.newTaskForm.controls.code.setValue(ServiceTask.ZARUKA_NAME); 
        this.newTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      default:
        return;
    }
  }

  // service api request
  submitNewTaskForm(): void {
    this.newTaskFormSubmitted = true;
    // stop here if form is invalid
    if (this.newTaskForm.invalid) {
      return;
    }

    // get form values
    this.newTask.car_key = <number>this.f_new.car_key.value;
    this.newTask.trailer_key = <number>this.f_new.trailer_key.value;
    this.newTask.amortization_interval = this.f_new.amortization_interval.value;
    this.newTask.amortization_unit = this.f_new.amortization_unit.value;
    this.newTask.code = this.f_new.code.value;
    this.newTask.currency = this.f_new.currency.value;
    this.newTask.completed_date = this.f_new.completed_date.value;
    this.newTask.completed_km = this.f_new.completed_km.value;
    this.newTask.due_date = this.f_new.due_date.value;
    this.newTask.due_km = this.f_new.due_km.value;
    this.newTask.price = this.f_new.price.value;
    this.newTask.price_depriciated = this.f_new.price_depriciated.value;
    this.newTask.note = this.f_new.note.value;
    this.newTask.reminder1 = this.f_new.reminder1.value;
    this.newTask.reminder2 = this.f_new.reminder2.value;
    this.newTask.task = this.f_new.task.value;
    this.newTask.type = this.f_new.type.value;

    console.log(this.newTask);

    this._subscribed.push(
      this._serviceTaskServ.createServiceTask(this.newTask).subscribe(
        response => {
          if (response) {
            console.log(response);
            (<any>$('#createModal')).modal('hide');
            // possibly automatic plan for next same task
            this.autoPlanTask(this.newTask);
            // emit event to parent
            this.updateTask.emit(true);
            this._updatedTaskObject.emit(response);
          }
        }
      )
    );
  }


  /***************************************************/
  /* Edit/detail service tasks */
  /***************************************************/
  public editTaskForm: FormGroup;
  public editTaskFormSubmitted = false;
  // convenience getter for easy access to form fields
  get f_edit() { 
    return this.editTaskForm.controls; 
  }

  get isCarEditTask(): boolean {
    return this.editTask.car_key != null && this.editTask.car_key != undefined;
  }

  get isTrailerEditTask(): boolean {
    return this.editTask.trailer_key != null && this.editTask.trailer_key != undefined;
  }

  initEditTaskForm(): void {
    this.editTaskForm = this._formBuilder.group({
      car_key: new FormControl({value: null, disabled: this._vehicle ? true : false}),
      trailer_key: new FormControl({value: null, disabled: this._trailer ? true : false}),
      amortization_interval: [null],
      amortization_unit: [null],
      code: [null, [Validators.required, Validators.maxLength(40)]],
      currency: [null],
      completed_date: [null],
      completed_km: [null],
      due_date: [null],
      due_km: [null],
      insurance_benefits: [null],
      price: [null],
      price_depriciated: [null],
      note: [''],
      reminder1: [null],
      reminder2: [null],
      task: [null, Validators.required],
      type: ['A']
    });
  }

  editTaskChange(): void {
    let value = this.f_edit.task.value;
    switch (value) {
      case 'A':
        this.editTaskForm.controls.code.setValue(ServiceTask.MYTO_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'B':
        this.editTaskForm.controls.code.setValue(ServiceTask.BATTERY_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'C':
        this.editTaskForm.controls.code.setValue(ServiceTask.PARKING_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'D':
        this.editTaskForm.controls.code.setValue(ServiceTask.DILY_NAKUP_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'G':
        this.editTaskForm.controls.code.setValue(ServiceTask.GARANCNI_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'H':
        this.editTaskForm.controls.code.setValue(ServiceTask.HAVARIE_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'I':
        this.editTaskForm.controls.code.setValue(ServiceTask.POJISTENI_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'L':
        this.editTaskForm.controls.code.setValue(ServiceTask.L_CERTIFIKAT_NAME); 
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'M':
        this.editTaskForm.controls.code.setValue(ServiceTask.MYTI_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_NOTPLANNED);
        break;
      case 'N':
        this.editTaskForm.controls.code.setValue(ServiceTask.NAKUP_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'O':
        this.editTaskForm.controls.code.setValue(ServiceTask.OIL_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'P':
        this.editTaskForm.controls.code.setValue(ServiceTask.PNEU1_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'Q':
        this.editTaskForm.controls.code.setValue(ServiceTask.PNEU2_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'R':
        this.editTaskForm.controls.code.setValue(ServiceTask.PNEU3_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'V':
        this.editTaskForm.controls.code.setValue(ServiceTask.TACHO_KALIBRACE_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'W':
        this.editTaskForm.controls.code.setValue(ServiceTask.TACHO_ZALOHA_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'T':
        this.editTaskForm.controls.code.setValue(ServiceTask.TECHNICKA_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      case 'Y':
        this.editTaskForm.controls.code.setValue(ServiceTask.NADSTANDARD_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_NADSTANDARD);
        break;
      case 'Z':
        this.editTaskForm.controls.code.setValue(ServiceTask.ZARUKA_NAME);
        this.editTaskForm.controls.type.setValue(ServiceTask.TYPE_PLANNED);
        break;
      default:
        return;
    }
  }

  // service api request
  submitEditTaskForm(): void {
    this.editTaskFormSubmitted = true;
    // stop here if form is invalid
    if (this.editTaskForm.invalid) {
      return;
    }

    // get form values
    this.editTask.car_key = <number>this.f_edit.car_key.value;
    this.editTask.trailer_key = <number>this.f_edit.trailer_key.value;
    this.editTask.amortization_interval = this.f_edit.amortization_interval.value;
    this.editTask.amortization_unit = this.f_edit.amortization_unit.value;
    this.editTask.code = this.f_edit.code.value;
    this.editTask.currency = this.f_edit.currency.value;
    this.editTask.completed_date = this.f_edit.completed_date.value;
    this.editTask.completed_km = this.f_edit.completed_km.value;
    this.editTask.due_date = this.f_edit.due_date.value;
    this.editTask.due_km = this.f_edit.due_km.value;
    this.editTask.insurance_benefits = this.f_edit.insurance_benefits.value;
    this.editTask.price = this.f_edit.price.value;
    this.editTask.price_depriciated = this.f_edit.price_depriciated.value;
    this.editTask.note = this.f_edit.note.value;
    this.editTask.reminder1 = this.f_edit.reminder1.value;
    this.editTask.reminder2 = this.f_edit.reminder2.value;
    this.editTask.task = this.f_edit.task.value;
    this.editTask.type = this.f_edit.type.value;

    this._subscribed.push(
      this._serviceTaskServ.updateServiceTask(this.editTask).subscribe(
        response => {
          if (response) {
            console.log(response);
            (<any>$('#editModal')).modal('hide');
            // possibly automatic plan for next same task
            this.autoPlanTask(this.editTask);
            // emit event to parent
            this.updateTask.emit(true);
            this._updatedTaskObject.emit(response);
          }
        }
      )
    );
  }


  /***************************************************/
  /* Automatic planning future task */
  /***************************************************/
  public planTask: ServiceTask = null;
  public planTaskForm: FormGroup;
  public planTaskFormSubmitted = false;
  // convenience getter for easy access to form fields
  get f_plan() { 
    return this.planTaskForm.controls; 
  }

  initPlanTaskForm(): void {
    this.planTaskForm = this._formBuilder.group({
      car_key: new FormControl({value: null, disabled: this._vehicle ? true : false}),
      trailer_key: new FormControl({value: null, disabled: this._trailer ? true : false}),
      amortization_interval: [null],
      amortization_unit: [null],
      code: [null, [Validators.required, Validators.maxLength(40)]],
      currency: [null],
      completed_date: [null],
      completed_km: [null],
      due_date: [null],
      due_km: [null],
      insurance_benefits: [null],
      price: [null],
      price_depriciated: [null],
      note: ['', Validators.maxLength(250)],
      reminder1: [null],
      reminder2: [null],
      task: new FormControl({value: 'null', disabled: true}, [Validators.required]),
      type: new FormControl({value: 'A', disabled: true})
    });
  }

  get isCarPlanTask(): boolean {
    return this.planTask.car_key != null && this.planTask.car_key != undefined;
  }

  get isTrailerPlanTask(): boolean {
    return this.planTask.trailer_key != null && this.planTask.trailer_key != undefined;
  }

  autoPlanTask(task: ServiceTask): void {
    // possible auto planning
    if (task.isPlanned) {
      this.initPlanTaskForm();
      this.planTaskForm.controls.car_key.setValue(task.car_key);
      this.planTaskForm.controls.trailer_key.setValue(task.trailer_key);
      this.planTaskForm.controls.task.setValue(task.task);
      this.planTaskForm.controls.code.setValue(task.code);
      this.planTaskForm.controls.amortization_interval.setValue(task.amortization_interval);
      this.planTaskForm.controls.amortization_unit.setValue(task.amortization_unit);
      this.planTaskForm.controls.reminder1.setValue(task.reminder1);
      this.planTaskForm.controls.reminder2.setValue(task.reminder2);
      this.planTaskForm.controls.currency.setValue(task.currency);
      this.planTask = new ServiceTask();
      this.planTask.car_key = task.car_key;
      this.planTask.trailer_key = task.trailer_key;

      if (task.amortization_interval && task.amortization_unit == 'D') {
        // days
        if (this.datePlanned(task.task) && task.completedDate) {
          let newDueTime: number = task.completedDate.getTime();
          newDueTime += task.amortization_interval * 24 * 60 * 60 * 1000;
          let newDueDate: Date = new Date(newDueTime);
          let newDueDateFormatted = this._datePipe.transform(newDueDate, 'yyyy-MM-dd');
          // init and open planForm modal
          this.planTaskForm.controls.due_date.setValue(newDueDateFormatted);
          (<any>$('#planModal')).modal('show');
        }
      }
      else if (task.amortization_interval && task.amortization_unit == 'K') {
        // km
        if (this.kmPlanned(task.task) && task.completed_km) {
          let newDueKm: number = task.completed_km + task.amortization_interval;
          // init and open planForm modal
          this.planTaskForm.controls.due_km.setValue(newDueKm);
          (<any>$('#planModal')).modal('show');
        }
      }
    }
  }

  // service api request
  submitPlanTaskForm(): void {
    this.planTaskFormSubmitted = true;
    // stop here if form is invalid
    if (this.planTaskForm.invalid) {
      return;
    }

    // get form values
    this.planTask.car_key = this.f_plan.car_key.value;
    this.planTask.trailer_key = this.f_plan.trailer_key.value;
    this.planTask.amortization_interval = this.f_plan.amortization_interval.value;
    this.planTask.amortization_unit = this.f_plan.amortization_unit.value;
    this.planTask.code = this.f_plan.code.value;
    this.planTask.currency = this.f_plan.currency.value;
    this.planTask.completed_date = this.f_plan.completed_date.value;
    this.planTask.completed_km = this.f_plan.completed_km.value;
    this.planTask.due_date = this.f_plan.due_date.value;
    this.planTask.due_km = this.f_plan.due_km.value;
    this.planTask.price = this.f_plan.price.value;
    this.planTask.price_depriciated = this.f_plan.price_depriciated.value;
    this.planTask.note = this.f_plan.note.value;
    this.planTask.reminder1 = this.f_plan.reminder1.value;
    this.planTask.reminder2 = this.f_plan.reminder2.value;
    this.planTask.task = this.f_plan.task.value;
    this.planTask.type = this.f_plan.type.value;

    this._subscribed.push(
      this._serviceTaskServ.createServiceTask(this.planTask).subscribe(
        response => {
          if (response) {
            console.log(response);
            (<any>$('#planModal')).modal('hide');
            // emit event to parent
            this.updateTask.emit(true);
          }
        }
      )
    );
  }


  /***************************************************/
  /* attributes of specific categories */
  /***************************************************/
  private _DATE_PLANNED_TASKS: Array<string> = [
    ServiceTask.TECHNICKA, 
    ServiceTask.TACHO_ZALOHA, 
    ServiceTask.TACHO_KALIBRACE, 
    ServiceTask.ZARUKA,
    ServiceTask.L_CERTIFIKAT
  ];
  datePlanned(task: string): boolean {
    return this._DATE_PLANNED_TASKS.includes(task);
  }

  private _KM_PLANNED_TASKS: Array<string> = [ ServiceTask.OIL ];
  kmPlanned(task: string): boolean {
    return this._KM_PLANNED_TASKS.includes(task);
  }

  private _BOTH_PLANNED_TASKS: Array<string> = [ 
    ServiceTask.GARANCNI, 
    ServiceTask.TERMINOVANA, 
    ServiceTask.OTHERS 
  ];
  bothPlanned(task: string): boolean {
    return this._BOTH_PLANNED_TASKS.includes(task);
  }

  private _NOT_PLANNED_TASKS: Array<string> = [
    ServiceTask.MYTO, 
    ServiceTask.BATTERY, 
    ServiceTask.PARKING, 
    ServiceTask.HAVARIE, 
    ServiceTask.NAKUP, 
    ServiceTask.MYTI, 
    ServiceTask.PNEU1, 
    ServiceTask.PNEU2, 
    ServiceTask.PNEU3, 
    ServiceTask.NADSTANDARD
  ];
  notPlanned(task: string): boolean {
    return this._NOT_PLANNED_TASKS.includes(task);
  }


  /***************************************************/
  /* kilometres/tachometer smart tool using events */
  /***************************************************/
  // arrays may be just one object after last fixes
  private _fuelEvents: Array<ServiceEventObject> = [];
  public get fuelEvents(): Array<ServiceEventObject> {
    return this._fuelEvents;
  }
  
  private _trackingEvents: Array<TrackingEvent> = [];
  public get trackingEvents(): Array<TrackingEvent> {
    return this._trackingEvents;
  }

  autoCompleteKm(): void {
    let car_key: number = null;
    let completed_date: string = null;

    if (this.newTask) {
      car_key = this.f_new.car_key.value;
      completed_date = this.f_new.completed_date.value;
    }
    else if (this.editTask) {
      car_key = this.f_edit.car_key.value;
      completed_date = this.f_edit.completed_date.value;
    }

    if (car_key && completed_date) {
      // date formatted
      let time_from_date: Date = new Date(completed_date);
      time_from_date.setHours(0);
      time_from_date.setMinutes(0);
      let time_from_api: string = DateTools.toCustomIsoApiFormat(time_from_date);
      
      let time_to_date: Date = new Date(completed_date);
      time_to_date.setHours(23);
      time_to_date.setMinutes(59);
      let time_to_api: string = DateTools.toCustomIsoApiFormat(time_to_date);

      let filterObj: any = {
        tf: time_from_api,
        tt: time_to_api,
        ck: car_key
      }

      this._subscribed.push(
        this._trackingEventServ.getTrackingEventsSeparately(filterObj).subscribe(
          (response: Array<any>) => {
            if (response && response.length) {
              this._trackingEvents = TrackingEventService.buildTrackingEventsFromData(response);
              this._trackingEvents = this._trackingEvents.filter(te => te.odometer != 0);
            }
          },
          error => {
            console.log(error);
          }
        )
      );

      // resize tf and tt interval for service events
      let sevenDaysLess: Date = time_from_date;
      sevenDaysLess.setDate(sevenDaysLess.getDate() - 7);
      filterObj['tf'] = DateTools.toCustomIsoApiFormat(sevenDaysLess);
      
      let sevenDaysMore: Date = time_to_date;
      sevenDaysMore.setDate(sevenDaysMore.getDate() + 7);
      filterObj['tt'] = DateTools.toCustomIsoApiFormat(sevenDaysMore);

      this._subscribed.push(
        this._serviceEventServ.getServiceEventsSeparately(filterObj).subscribe(
          (response: Array<any>) => {
            if (response && response.length) {
              this._fuelEvents = ServiceEventService.buildServiceEventsFromData(response);
              this._fuelEvents = this._fuelEvents.filter(
                se => se.type == ServiceEventObject.TYPE_REFUELING
              );

              // keep only one closest in array
              let diffTime: number;
              let fuelEvent: ServiceEventObject = null;
              this._fuelEvents.forEach(
                (se, idx) => {
                  let tmpDiff = Math.abs(new Date(completed_date).getTime() - se.timeDate.getTime());
                  // init with first
                  if (idx == 0) {
                    fuelEvent = se;
                    diffTime = tmpDiff;
                  }
                  // find lowest diff
                  else if (tmpDiff < diffTime) {
                    fuelEvent = se;
                    diffTime = tmpDiff;
                  }
                }
              );
              // just array with one closest fuel event
              if (fuelEvent) {
                this._fuelEvents = [fuelEvent];
              }
            }
          }
        )
      );
    }
  }

  newCompletedKm(value: number): void {
    this.newTaskForm.controls.completed_km.setValue(value);
  }

  editCompletedKm(value: number): void {
    this.editTaskForm.controls.completed_km.setValue(value);
  }
}
